import * as React from 'react';
import { useRouter } from 'next/router';
import firebase from 'firebase/app';
import { watchViewerTokenChanges } from 'services/client/token';
import { getIsNativePlatform } from 'utils/client/mobile';
import { AuthStatus } from 'constants/auth';
import { Viewer } from 'constants/user';
import { hasDashboardAccess } from 'services/client/token';
import api from 'services/client/api';
import {
  authPages,
  privatePages,
  LOGIN_PAGE,
  OPTION_FLOW_PAGE,
  EMAIL_VERIFICATION_PAGE,
  SETTINGS_PAGE,
  ALERTS_SETTINGS_PAGE,
} from 'constants/pages';

const ON_SUCCESSFUL_AUTH_PAGE = OPTION_FLOW_PAGE;

// @ts-ignore add this to global window object
const INJECTED_PAGE_DATA = typeof window !== 'undefined' ? window?.injectedPageData : undefined;
let hasSetCookie = false;

const DEFAULT_USER: Viewer = {
  status: AuthStatus.Loading,
  viewer: null,
  config: null,
  claims: null,
};

export const ViewerContext = React.createContext(DEFAULT_USER);

const mayInitRetentionSnippet = (user: firebase.User | null) => {
  const email = user?.email;
  const hasEmail = !!email;

  // @ts-ignore need to declare profitwell
  if (hasEmail && typeof profitwell !== 'undefined' && !getIsNativePlatform()) {
    // @ts-ignore need to declare profitwell
    profitwell('start', { user_email: email });
  }
};

export const ViewerProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [viewer, setViewer] = React.useState(DEFAULT_USER);
  const router = useRouter();

  React.useEffect(() => {
    watchViewerTokenChanges(({ user, claims }) => {
      const pathname = router.pathname;

      mayInitRetentionSnippet(user);

      if (user) {
        try {
          if (
            hasDashboardAccess({
              status: AuthStatus.User,
              viewer: user,
              config: null,
              claims,
            }) &&
            !hasSetCookie
            // && !capacitor is mobile
          ) {
            hasSetCookie = true;
            api.get('self/dashboard-state');
          }
        } catch (error) {}

        if (
          (pathname === EMAIL_VERIFICATION_PAGE && !user.emailVerified) ||
          pathname === SETTINGS_PAGE ||
          pathname === ALERTS_SETTINGS_PAGE
        ) {
          setViewer({
            status: AuthStatus.User,
            viewer: user,
            config: null,
            claims,
          });
          return;
        }

        if (pathname === EMAIL_VERIFICATION_PAGE && user.emailVerified) {
          if (getIsNativePlatform()) {
            router.push(ON_SUCCESSFUL_AUTH_PAGE);
          } else {
            window.location.href = ON_SUCCESSFUL_AUTH_PAGE;
          }
          return;
        }

        if (privatePages.includes(pathname) && !user.emailVerified) {
          if (getIsNativePlatform()) {
            router.push(EMAIL_VERIFICATION_PAGE);
          } else {
            window.location.href = EMAIL_VERIFICATION_PAGE;
          }
          return;
        }

        if (authPages.includes(pathname)) {
          if (getIsNativePlatform()) {
            router.push(ON_SUCCESSFUL_AUTH_PAGE);
          } else {
            window.location.href = ON_SUCCESSFUL_AUTH_PAGE;
          }
          return;
        }

        setViewer({
          status: AuthStatus.User,
          viewer: user,
          config: null,
          claims,
        });
      } else {
        setViewer({
          status: AuthStatus.Anonymous,
          viewer: null,
          config: null,
          claims: null,
        });

        if (!INJECTED_PAGE_DATA && privatePages.includes(pathname)) {
          const redirectUrl = pathname ? `${LOGIN_PAGE}?next=${pathname}` : LOGIN_PAGE;
          router.push(redirectUrl);
          return;
        }
      }
    });
  }, []);

  return <ViewerContext.Provider value={viewer}>{children}</ViewerContext.Provider>;
};
