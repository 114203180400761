import { Company } from 'constants/auth';
import { CarbonIconType, LogoFacebook20, LogoLinkedin20, LogoTwitter20 } from '@carbon/icons-react';
import { IconType } from 'react-icons';
import { FaGoogle } from 'react-icons/fa';
import { SiDiscord } from 'react-icons/si';

type CompanyMap = Record<Company, string>;
type CompanyMapIcon = Record<Company, CarbonIconType | IconType>;

export const color: CompanyMap = {
  [Company.Discord]: '#7289da',
  [Company.Facebook]: '#3C5B96',
  [Company.Twitter]: '#1E99E6',
  [Company.Google]: '#DB4C3F',
  [Company.Linkedin]: '#2789BC',
};

export const hover: CompanyMap = {
  [Company.Discord]: '#7289da',
  [Company.Facebook]: '#304D8A',
  [Company.Twitter]: '#34A1E5',
  [Company.Google]: '#E0321C',
  [Company.Linkedin]: '#147BAF',
};

export const icon: CompanyMapIcon = {
  [Company.Discord]: SiDiscord,
  [Company.Facebook]: LogoFacebook20,
  [Company.Twitter]: LogoTwitter20,
  [Company.Google]: FaGoogle,
  [Company.Linkedin]: LogoLinkedin20,
};

export const name: CompanyMap = {
  [Company.Discord]: 'Discord',
  [Company.Facebook]: 'Facebook',
  [Company.Twitter]: 'Twitter',
  [Company.Google]: 'Google',
  [Company.Linkedin]: 'LinkedIn',
};

export default {
  Company,
  color,
  hover,
  icon,
  name,
};
