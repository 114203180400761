import * as Sentry from '@sentry/nextjs';
import { NextApiRequest } from 'next';

/**
 * Configure the Sentry scope by extracting useful tags and context from the given request.
 *
 * @param req
 */
export const configureReq = (req: NextApiRequest) => {
  Sentry.configureScope((scope) => {
    scope.setTag('host', req?.headers?.host);
    scope.setTag('url', req.url || '');
    scope.setTag('method', req.method || '');
    scope.setContext('query', req.query);
    scope.setContext('cookies', req.cookies);
    scope.setContext('body', req.body);
    scope.setContext('headers', req.headers);
  });
};

export default Sentry;
