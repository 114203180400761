import { type ReactNode, createContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import { captureException } from '@sentry/nextjs';
import { type Geo } from '@vercel/edge';
import { AuthStatus } from 'constants/auth';
import { RequestStatus } from 'constants/requests';
import { type GetCurrentUserQuery, useGetCurrentUserLazyQuery } from 'types/generated/client';
import { identify } from 'services/client/analytics';
import api from 'services/client/api';
import useViewer from 'hooks/useViewer';

const IP_PATH = 'ip';

interface IpResponse extends Geo {
  ip?: string;
}

export type CurrentUserResponse =
  | (ReturnType<typeof useGetCurrentUserLazyQuery>[1] & {
      user?: GetCurrentUserQuery['users_by_pk'] | null;
    })
  | null;
interface CurrentUser {
  currentUser: CurrentUserResponse;
  geo: null | (IpResponse & { requestStatus: RequestStatus });
}

const DEFAULT_USER: CurrentUser = {
  currentUser: null,
  geo: null,
};

export const CurrentUserContext = createContext(DEFAULT_USER);

export const CurrentUserProvider = ({ children }: { children: ReactNode }) => {
  const session = useViewer();
  const userId = session.viewer?.uid;
  const [ipResponse, setIpResponse] = useState<IpResponse | null>(null);
  const [ipRequestStatus, setIpRequestStatus] = useState(RequestStatus.Idle);
  const [hasIdentifiedUser, setHasIdentifiedUser] = useState(false);

  const [getCurrentUserLazyQuery, queryResult] = useGetCurrentUserLazyQuery();
  const { data } = queryResult;

  useEffect(() => {
    if (data?.users_by_pk?.id && !hasIdentifiedUser) {
      setHasIdentifiedUser(true);
      try {
        const user = data.users_by_pk;
        const { ...userAttributes } = user;
        identify({
          ...userAttributes,
          email: user.email,
          name: `${user.firstName || ''} ${user.lastName || ''}`.trim(),
          userId: user.id,
          additionalUserParams: {
            ...userAttributes,
          },
        });
      } catch (error) {
        Sentry.captureException(error);
      }
      try {
        // @ts-ignore should be defined
        gtag('set', 'user_properties', { user_id: data.users_by_pk.id });
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  }, [data, hasIdentifiedUser]);

  useEffect(() => {
    if (!!userId && session.status === AuthStatus.User) {
      /**
       * @todo [LAUNCH]: logout issue?
       */
      // NOTE: Should we check for firebase token here?
      // There are some edge race conditions where it's fetching without a token, but it seems rare and to not effect usability.
      getCurrentUserLazyQuery({ variables: { id: userId } });
    }
  }, [session.status, userId, getCurrentUserLazyQuery]);

  useEffect(() => {
    const fetchIp = async () => {
      setIpRequestStatus(RequestStatus.InProgress);
      try {
        /**
         * @todo convert to react-query
         */
        const data: IpResponse = await api.get(IP_PATH);
        setIpResponse({
          ...data,
          city: decodeURIComponent(data.city || ''),
          region: decodeURIComponent(data.region || ''),
        });
        setIpRequestStatus(RequestStatus.Success);
      } catch (error) {
        captureException(error);
        setIpRequestStatus(RequestStatus.Error);
      }
    };
    fetchIp();
  }, []);

  // NOTE: This ensures we only return the current user if the session exists from firebase.
  // It is possible the current user could remain in the apollo cache and won't update until the useEffect above runs.
  const currentUser = { ...queryResult, user: userId ? data?.users_by_pk : null };

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser,
        geo: { ...ipResponse, requestStatus: ipRequestStatus },
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};
