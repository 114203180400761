import * as React from 'react';
import * as Sentry from '@sentry/nextjs';
import { Geo } from '@vercel/edge';
import { Capacitor } from '@capacitor/core';
import { useRouter } from 'next/router';
import useViewer from 'hooks/useViewer';
import { useInsertUsagePingMutation } from 'types/generated/client';
import api from 'services/client/api';

const IP_PATH = 'ip';

interface IpResponse extends Geo {
  ip: string;
}

const UsagePing: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const router = useRouter();
  const viewer = useViewer();
  const userId = viewer.viewer?.uid;
  const isReady = router.isReady;
  const pathname = router.pathname;
  const query = router.query;
  const [insert] = useInsertUsagePingMutation();

  React.useEffect(() => {
    if (userId && isReady) {
      const queryString = (window.location.search || '').replace('?', '');
      api
        .get(IP_PATH)
        .then((ipResponse: IpResponse) => {
          return insert({
            variables: {
              userId,
              pathname,
              queryString,
              city: ipResponse.city,
              country: ipResponse.country,
              ip: ipResponse.ip,
              region: ipResponse.region,
              platform: Capacitor.getPlatform(),
            },
          });
        })
        .catch((error) => {
          Sentry.captureException(error);
        });
    }
  }, [userId, pathname, isReady, insert, query]);

  return <>{children}</>;
};

export default UsagePing;
