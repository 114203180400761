import firebase from 'firebase/app';
import { auth, database } from 'services/client/firebase';
import { LOGIN_PAGE } from 'constants/pages';
import { HASURA_CLAIMS_KEY, HasuraClaimKey } from 'constants/auth';
import { Viewer } from 'constants/user';
import { fromPostgresArray } from 'utils/shared/hasura';
import { getIsNativePlatform } from 'utils/client/mobile';
import { getDashboardSubscriptionName, getAlertSubscriptionName } from 'constants/payments';

const DASHBOARD_SUBSCRIPTION = getDashboardSubscriptionName();
const ALERT_SUBSCRIPTION = getAlertSubscriptionName();

export type FirebaseUserOrNull = firebase.User | null;
type TokenOrNull = string | null;
interface UpdateResponse {
  user: FirebaseUserOrNull;
  token: TokenOrNull;
  claims: null;
}
type FirebaseCallback = ({ user, token }: UpdateResponse) => void;

const MINUTE = 60 * 1000;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const MAX_LOGIN_TIME = 7 * DAY;

let token: TokenOrNull = null;

export const updateViewerToken = (
  user: FirebaseUserOrNull,
  shouldForceRefresh?: boolean,
): Promise<UpdateResponse> => {
  return new Promise(async (resolve) => {
    if (user) {
      const firebaseToken = await user.getIdToken(shouldForceRefresh);
      const idTokenResult = await user.getIdTokenResult();
      const hasuraClaim = idTokenResult.claims[HASURA_CLAIMS_KEY];
      token = firebaseToken;

      if (
        idTokenResult.claims.auth_time &&
        idTokenResult.claims.auth_time * 1000 + MAX_LOGIN_TIME < Date.now()
      ) {
        await auth.signOut();
        if (getIsNativePlatform()) {
          window.location.reload();
        } else {
          window.location.href = LOGIN_PAGE;
        }
      }

      if (hasuraClaim) {
        return resolve({ user, token, claims: hasuraClaim });
      } else {
        // Check if refresh is required.
        const metadataRef = database.ref('metadata/' + user.uid + '/refreshTime');

        metadataRef.on('value', async (data) => {
          if (!data.exists()) return;
          // Force refresh to pick up the latest custom claims changes.
          token = await user.getIdToken(true);
          const idTokenResult = await user.getIdTokenResult();
          const hasuraClaim = idTokenResult.claims['https://hasura.io/jwt/claims'];

          return resolve({ user, token, claims: hasuraClaim });
        });
      }
    } else {
      token = null;
      return resolve({ user, token, claims: null });
    }
  });
};

// NOTE: This should probably be a React hook and manage sub/unsub of these methods.
// However, with the client-side routing, it seems to only mount once and work correctly,
// but should be monitored for potential leaks.
export const watchViewerTokenChanges = (callback: FirebaseCallback) => {
  auth.onAuthStateChanged(async (user) => {
    updateViewerToken(user, true).then((res: UpdateResponse) => callback(res));
  });
  // auth.onIdTokenChanged(async (user) => {
  //   updateViewerToken(user).then((res: UpdateResponse) => callback(res));
  // });
};

export const getViewerToken = async () => {
  const currentUserToken = await auth?.currentUser?.getIdToken();
  return currentUserToken;
};

export const hasDashboardAccess = (viewer: Viewer) => {
  if (!viewer?.viewer || !viewer?.claims) {
    return false;
  }

  const claims = viewer.claims;
  const subscriptions = fromPostgresArray(claims[HasuraClaimKey.Subscriptions]);
  const launchAccessEndsAt = claims[HasuraClaimKey.LaunchAccess];

  const isSubscribedUser = subscriptions.includes(DASHBOARD_SUBSCRIPTION);
  const isFreeUser = !!launchAccessEndsAt && new Date(launchAccessEndsAt).getTime() > Date.now();

  return isSubscribedUser || isFreeUser;
};

export const hasAlertAccess = (viewer: Viewer) => {
  if (!viewer?.viewer || !viewer?.claims) {
    return false;
  }

  const isDashboardUser = hasDashboardAccess(viewer);

  const claims = viewer.claims;
  const subscriptions = fromPostgresArray(claims[HasuraClaimKey.Subscriptions]);
  const isAlertsUser = subscriptions.includes(ALERT_SUBSCRIPTION);

  return isDashboardUser || isAlertsUser;
};

export const hasAlertSubscription = (viewer: Viewer) => {
  if (!viewer?.viewer || !viewer?.claims) {
    return false;
  }

  const claims = viewer.claims;
  const subscriptions = fromPostgresArray(claims[HasuraClaimKey.Subscriptions]);
  const isAlertsUser = subscriptions.includes(ALERT_SUBSCRIPTION);

  return isAlertsUser;
};

export const hasCompletedOpraAgreement = (viewer: Viewer) => {
  if (!viewer?.viewer || !viewer?.claims) {
    return false;
  }

  const claims = viewer.claims;
  const opraAgreementEndsAt = claims[HasuraClaimKey.OpraAgreement];

  return !!opraAgreementEndsAt;
};

export const shouldRevalidateOpra = (viewer: Viewer) => {
  if (!viewer?.viewer || !viewer?.claims) {
    return false;
  }

  const claims = viewer.claims;
  const opraAgreementEndsAt = claims[HasuraClaimKey.OpraAgreement];

  return !opraAgreementEndsAt || Date.now() > new Date(opraAgreementEndsAt).getTime();
};
