import { getViewerToken } from 'services/client/token';
import { getIsNativePlatform } from 'utils/client/mobile';
import ApiService from './ApiService';

const API_ROOT = getIsNativePlatform() ? process.env.REMOTE_API_URL : process.env.APP_URL;
const API_URL = `${API_ROOT}/api/v1`;

const api = new ApiService({
  baseUrl: API_URL,
  injectHeaders: async () => {
    const token = await getViewerToken();

    if (token) {
      return { Authorization: `Bearer ${token}` };
    } else {
      return {};
    }
  },
});

export default api;
