import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/database';

const DEV_CONFIG = {
  apiKey: 'AIzaSyC9TjIx6_R8OtMCGXCG8TyF76D7MTAoZIc',
  authDomain: 'insiderfinance-dev.firebaseapp.com',
  databaseURL: 'https://insiderfinance-dev.firebaseio.com',
  projectId: 'insiderfinance-dev',
  storageBucket: 'insiderfinance-dev.appspot.com',
  messagingSenderId: '11289054233',
  appId: '1:11289054233:web:7080d275de7eb06586ae88',
  measurementId: 'G-3P7Q3VD7Y0',
};

const PROD_CONFIG = {
  apiKey: 'AIzaSyAFarO5T_hA4UrIhhPtgfMQraVwTDd_cr0',
  authDomain: 'insiderfinance-prod.firebaseapp.com',
  databaseURL: 'https://insiderfinance-prod.firebaseio.com',
  projectId: 'insiderfinance-prod',
  storageBucket: 'insiderfinance-prod.appspot.com',
  messagingSenderId: '287855774002',
  appId: '1:287855774002:web:efbf8b9185641135376f2b',
  measurementId: 'G-RC0JWMZBV7',
};

export const firebaseConfig = process.env.APP_STAGE !== 'production' ? DEV_CONFIG : PROD_CONFIG;

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const app = firebase.app();
const auth = firebase.auth();
const storage = firebase.storage();
const database = firebase.database();

if (process.env.APP_STAGE !== 'production') {
  console.log(app.name ? 'Firebase Mode Activated!' : 'Firebase not working :(');
}

export { auth, storage, database };
