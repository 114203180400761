import Stripe from 'stripe';

export const CHECKOUT_COMPLETE_PAGE = '/checkout/complete';

export const UNKNOWN = 'unknown';

export const DASHBOARD_TRIAL_FOR_TECHNICAL_ANALYSIS = 'price_1LVaAnGSER20gXK74J41TVpD';
export const IGNORED_PRICE_IDS = [DASHBOARD_TRIAL_FOR_TECHNICAL_ANALYSIS];

export const LIFETIME_COUPON_DEV = 'vLE4MmFH';
export const LIFETIME_COUPON_PROD = 'i4higNuN';
export const RESUBSCRIBE_COUPON_DEV = 'xAS5ChlQ';
export const RESUBSCRIBE_COUPON_PROD = 'qIutDmoD';
export const UPGRADE_COUPON_DEV = 'SOCuHt6Z';
export const UPGRADE_COUPON_PROD = 'ci65nl1X';

export const LIFETIME_DASHBOARD_PRICE_DEV = 'price_1OCsekGSER20gXK73vnK8JlF';
export const LIFETIME_DASHBOARD_PRICE_PROD = 'price_1OCsa5GSER20gXK7iWaUV729';
export const LIFETIME_ALERTS_PRICE_DEV = 'price_1OCsddGSER20gXK7e168gsV0';
export const LIFETIME_ALERTS_PRICE_PROD = 'price_1OCsbcGSER20gXK7gJgm8jZG';
export const ANNUAL_DASHBOARD_PRICE_DEV = 'price_1MRIcVGSER20gXK7vcHCxYtE';
export const ANNUAL_DASHBOARD_PRICE_PROD = 'price_1MZyTSGSER20gXK7Y8W4zdOa';
export const ANNUAL_ALERTS_PRICE_DEV = 'price_1MZyKOGSER20gXK7prBvxi5G';
export const ANNUAL_ALERTS_PRICE_PROD = 'price_1MZyUYGSER20gXK7K6mrPGOS';

export const productTerms = {
  biweekly: 'biweekly',
  monthly: 'monthly',
  quarterly: 'quarterly',
  annual: 'annual',
  lifetime: 'lifetime',
};

export enum SubscriptionType {
  SmartMoneyAlerts = 'SMART_MONEY_ALERTS',
  DashboardStandard = 'DASHBOARD_STANDARD',
}

export const ALERTS_SHORT_NAME = 'alerts';
export const DASHBOARD_SHORT_NAME = 'dashboard';
export type SubscriptionTypeShortNames = typeof ALERTS_SHORT_NAME | typeof DASHBOARD_SHORT_NAME;
export const JwtSubscriptionName = {
  [SubscriptionType.SmartMoneyAlerts]: ALERTS_SHORT_NAME,
  [SubscriptionType.DashboardStandard]: DASHBOARD_SHORT_NAME,
};

export const DisplayedSubscriptionNames = {
  [SubscriptionType.SmartMoneyAlerts]: 'Technical Analysis System',
  [SubscriptionType.DashboardStandard]: 'Real-time Options Dashboard',
};

export const getDashboardSubscriptionName = () => {
  return JwtSubscriptionName[SubscriptionType.DashboardStandard];
};

export const getAlertSubscriptionName = () => {
  return JwtSubscriptionName[SubscriptionType.SmartMoneyAlerts];
};

export enum SaleStatus {
  Unpaid = 'UNPAID',
  Paid = 'PAID',
  Refund = 'REFUND',
}

export enum SaleTerm {
  BiWeekly = 'BIWEEKLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Annual = 'ANNUAL',
  Lifetime = 'LIFETIME',
}

export const ProductionAlertPrices = {
  test: 'price_1LUUpuGSER20gXK7Y6FBxd87',
  monthly: 'price_1LUUpuGSER20gXK7Y6FBxd87',
  quarterly: 'price_1LUUqQGSER20gXK7ZnIl8H6i',
  annual: 'price_1LUUrLGSER20gXK7oLEFGIMj',
  lifetime: 'price_1Lv0DEGSER20gXK74MGtzdg9',
};

export const DevAlertPrices = {
  test: 'price_1HwAfpGSER20gXK7ukj7UdoW',
  monthly: 'price_1HwAfpGSER20gXK7ukj7UdoW',
  quarterly: 'price_1HwAgxGSER20gXK7cvyzkstC',
  annual: 'price_1HwAhcGSER20gXK7oPemOvRf',
  lifetime: 'price_1MTZL9GSER20gXK76LPMcHa6',
};

export const ProductionDashboardPrices = {
  test: 'price_1J0620GSER20gXK7n3y0xnPi',
  biweekly: 'price_1J5yT7GSER20gXK7qrUHcBsF',
  monthly: 'price_1J0620GSER20gXK7n3y0xnPi',
  quarterly: 'price_1J0624GSER20gXK74yDNoArf',
  annual: 'price_1J0627GSER20gXK7cb7meYox',
  lifetime: 'price_1LhEkTGSER20gXK7kAXbk7Zf',
};

export const DevDashboardPrices = {
  test: 'price_1J05rfGSER20gXK7tuZFIklF',
  biweekly: 'price_1J5yT4GSER20gXK7FEPOf3Tx',
  monthly: 'price_1J05rfGSER20gXK7tuZFIklF',
  quarterly: 'price_1J05sFGSER20gXK77qatAgH5',
  annual: 'price_1J05t0GSER20gXK7NHV5dWS7',
  lifetime: 'price_1MTZIXGSER20gXK7i68kxtPv',
};

export const ProductionExclusiveEventPrices = {
  monthly: 'price_1KvmiaGSER20gXK7IFQSkXUO',
  quarterly: 'price_1Kvmj0GSER20gXK7rXelbX1w',
  annual: 'price_1LN1cZGSER20gXK769XQsivx',
};

/**
 * @note Just using normal prices for dev so it will run
 */
export const DevExclusiveEventPrices = DevDashboardPrices;

export const ProductionUpgradeDashboardPrices = {
  [productTerms.annual]: ANNUAL_DASHBOARD_PRICE_PROD,
  [productTerms.lifetime]: LIFETIME_DASHBOARD_PRICE_PROD,
};
export const DevUpgradeDashboardPrices = {
  [productTerms.annual]: ANNUAL_DASHBOARD_PRICE_DEV,
  [productTerms.lifetime]: LIFETIME_DASHBOARD_PRICE_DEV,
};
export const ProductionUpgradeAlertPrices = {
  [productTerms.annual]: ANNUAL_ALERTS_PRICE_PROD,
  [productTerms.lifetime]: LIFETIME_ALERTS_PRICE_PROD,
};
export const DevUpgradeAlertPrices = {
  [productTerms.annual]: ANNUAL_ALERTS_PRICE_DEV,
  [productTerms.lifetime]: LIFETIME_ALERTS_PRICE_DEV,
};

// COUPONS

export const ProductionDashboardPricesToCoupons = {
  [ProductionDashboardPrices.test]: 'YXJ0n33w',
  [ProductionDashboardPrices.monthly]: 'YXJ0n33w',
  [ProductionDashboardPrices.quarterly]: 'CTeueEuC',
  [ProductionDashboardPrices.annual]: 'xdTtTD75',
};

export const DevDashboardPricesToCoupons = {
  [DevDashboardPrices.test]: 'uuXSntzx',
  [DevDashboardPrices.monthly]: 'uuXSntzx',
  [DevDashboardPrices.quarterly]: 'Bs2dZnxp',
  [DevDashboardPrices.annual]: 'K0Oa8zwE',
};

export const AlertPrices =
  process.env.APP_STAGE === 'production' ? ProductionAlertPrices : DevAlertPrices;
export const DashboardPrices =
  process.env.APP_STAGE === 'production' ? ProductionDashboardPrices : DevDashboardPrices;
export const UpgradeAlertPrices =
  process.env.APP_STAGE === 'production' ? ProductionUpgradeAlertPrices : DevUpgradeAlertPrices;
export const UpgradeDashboardPrices =
  process.env.APP_STAGE === 'production'
    ? ProductionUpgradeDashboardPrices
    : DevUpgradeDashboardPrices;
export const PriceToCoupons =
  process.env.APP_STAGE === 'production'
    ? ProductionDashboardPricesToCoupons
    : DevDashboardPricesToCoupons;
export const LIFETIME_COUPON =
  process.env.APP_STAGE === 'production' ? LIFETIME_COUPON_PROD : LIFETIME_COUPON_DEV;
export const RESUBSCRIBE_COUPON =
  process.env.APP_STAGE === 'production' ? RESUBSCRIBE_COUPON_PROD : RESUBSCRIBE_COUPON_DEV;
export const UPGRADE_COUPON =
  process.env.APP_STAGE === 'production' ? UPGRADE_COUPON_PROD : UPGRADE_COUPON_DEV;
export const LIFETIME_DASHBORD_PRICE =
  process.env.APP_STAGE === 'production'
    ? LIFETIME_DASHBOARD_PRICE_PROD
    : LIFETIME_DASHBOARD_PRICE_DEV;
export const LIFETIME_ALERTS_PRICE =
  process.env.APP_STAGE === 'production' ? LIFETIME_ALERTS_PRICE_PROD : LIFETIME_ALERTS_PRICE_DEV;

export const productTypes = {
  dashboard: 'dashboard',
  alerts: 'alerts',
};

export const getPriceIdForRedirect = (product: string, term: string) => {
  if (product !== productTypes.dashboard && product !== productTypes.alerts) {
    return null;
  }

  if (
    term !== productTerms.biweekly &&
    term !== productTerms.monthly &&
    term !== productTerms.quarterly &&
    term !== productTerms.annual
  ) {
    return null;
  }

  if (product === productTypes.dashboard) {
    // @ts-ignore checking if it exists above
    return DashboardPrices[term];
  }

  if (product === productTypes.alerts) {
    // @ts-ignore checking if it exists above
    return AlertPrices[term];
  }

  return null;
};

// This feels very brittle. Is it even valuable?
export const PriceToLength = {
  [AlertPrices.test]: SaleTerm.Monthly,
  [AlertPrices.monthly]: SaleTerm.Monthly,
  [AlertPrices.quarterly]: SaleTerm.Quarterly,
  [AlertPrices.annual]: SaleTerm.Annual,
  [DashboardPrices.test]: SaleTerm.Monthly,
  [DashboardPrices.biweekly]: SaleTerm.BiWeekly,
  [DashboardPrices.monthly]: SaleTerm.Monthly,
  [DashboardPrices.quarterly]: SaleTerm.Quarterly,
  [DashboardPrices.annual]: SaleTerm.Annual,
  // Exclusive event prices
  [ProductionExclusiveEventPrices.monthly]: SaleTerm.Monthly,
  [ProductionExclusiveEventPrices.quarterly]: SaleTerm.Quarterly,
  [ProductionExclusiveEventPrices.annual]: SaleTerm.Annual,
};

export const oldPrices = [];

export const validPrices = [
  ...oldPrices,
  ...Object.values(ProductionAlertPrices),
  ...Object.values(ProductionDashboardPrices),
  ...Object.values(DevAlertPrices),
  ...Object.values(DevDashboardPrices),
];

const STRIPE_PRICE_PREFIX = 'price_';
export const isValidPriceId = (id?: string) => {
  if (!id) {
    return null;
  }

  // NOTE: Changed this to include anything that has price_ since we're adding quite a few more products. Manage what is actually allowed in Stripe..?
  return validPrices.includes(id) || id.includes(STRIPE_PRICE_PREFIX);
};

export type SubscriptionStatusValues = Stripe.Subscription.Status;

export const SubscriptionStatus: Record<string, SubscriptionStatusValues> = {
  Active: 'active',
  Canceled: 'canceled',
  Incomplete: 'incomplete',
  IncompleteExpired: 'incomplete_expired',
  PastDue: 'past_due',
  Trialing: 'trialing',
  Unpaid: 'unpaid',
};

export type InvoiceStatusValues = Stripe.Invoice.Status;

export const InvoiceStatus: Record<string, InvoiceStatusValues> = {
  Deleted: 'deleted',
  Draft: 'draft',
  Open: 'open',
  Paid: 'paid',
  Uncollectible: 'uncollectible',
  Void: 'void',
};

export enum PaymentProcessor {
  Stripe = 'STRIPE',
  Paypal = 'PAYPAL',
  Credit = 'CREDIT',
}

export enum DiscountType {
  Percent = 'PERCENT',
  Dollar = 'DOLLAR',
  FixedPrice = 'FIXED_PRICE',
}

export enum ProductStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Archived = 'ARCHIVED',
  DELETED = 'DELETED',
}

const successUrl = `${process.env.APP_URL}${CHECKOUT_COMPLETE_PAGE}`;
const cancelUrl = `${process.env.APP_URL}?subscribed=false`; // TODO: This will become a downsell page /checkout/cancel

export const redirectUrls: Record<SubscriptionType, { success_url: string; cancel_url: string }> = {
  [SubscriptionType.SmartMoneyAlerts]: {
    success_url: successUrl,
    cancel_url: cancelUrl,
  },
  [SubscriptionType.DashboardStandard]: {
    success_url: successUrl,
    cancel_url: cancelUrl,
  },
};

export const FUNNEL_SUBSCRIPTION_TYPE = 'type';
export const FUNNEL_DURATION = 'duration';
export const FUNNEL_PRICE_ID = 'product';
interface RedirectUrlParams {
  subscriptionType: SubscriptionType;
  priceId: string;
}
export const getRedirectUrls = ({ subscriptionType, priceId }: RedirectUrlParams) => {
  const duration = PriceToLength[priceId] || UNKNOWN;
  const type = JwtSubscriptionName[subscriptionType] || UNKNOWN;

  return {
    success_url: `${successUrl}?${FUNNEL_SUBSCRIPTION_TYPE}=${type}&${FUNNEL_DURATION}=${duration}&${FUNNEL_PRICE_ID}=${priceId}`,
    cancel_url: cancelUrl,
  };
};

export interface CheckoutBody {
  token: string;
  stripePriceId: string;
  subscriptionType: SubscriptionType;
}

export interface PortalBody {
  token: string;
}

interface UpgradeParams {
  requestedSubscriptionType: SubscriptionTypeShortNames;
  requestedDuration: SaleTerm;
}

export const getPriceIdForFunnelUpgrade = async ({
  requestedSubscriptionType,
  requestedDuration,
}: UpgradeParams) => {
  console.log('requestedSubscriptionType', requestedSubscriptionType);
  console.log('requestedDuration', requestedDuration);
  const durationKey = requestedDuration.toLowerCase() as
    | typeof productTerms.lifetime
    | typeof productTerms.annual;
  let priceId = '';
  let subscriptionType = '';

  if (requestedSubscriptionType.toLowerCase() === ALERTS_SHORT_NAME) {
    // TODO: Add prices to the upgrade map
    priceId = UpgradeAlertPrices[durationKey] || '';
    subscriptionType = SubscriptionType.SmartMoneyAlerts;
  } else if (requestedSubscriptionType.toLowerCase() === DASHBOARD_SHORT_NAME) {
    priceId = UpgradeDashboardPrices[durationKey] || '';
    subscriptionType = SubscriptionType.DashboardStandard;
  }

  const duration = priceId ? durationKey : '';

  return { priceId, duration, subscriptionType };
};

export const getPriceIdForResubscribe = async ({
  requestedSubscriptionType,
  requestedDuration,
}: UpgradeParams) => {
  console.log('requestedSubscriptionType', requestedSubscriptionType);
  console.log('requestedDuration', requestedDuration);
  const durationKey = requestedDuration.toLowerCase();
  let priceId = '';
  let subscriptionType = '';

  if (requestedSubscriptionType.toLowerCase() === ALERTS_SHORT_NAME) {
    // @ts-ignore
    priceId = AlertPrices[durationKey] || '';
    subscriptionType = SubscriptionType.SmartMoneyAlerts;
  } else if (requestedSubscriptionType.toLowerCase() === DASHBOARD_SHORT_NAME) {
    // @ts-ignore
    priceId = DashboardPrices[durationKey] || '';
    subscriptionType = SubscriptionType.DashboardStandard;
  }

  const duration = priceId ? durationKey : '';

  return { priceId, duration, subscriptionType };
};

export const getPriceIdForActiveUpgrade = async ({
  requestedSubscriptionType,
  requestedDuration,
}: UpgradeParams) => {
  console.log('requestedSubscriptionType', requestedSubscriptionType);
  console.log('requestedDuration', requestedDuration);
  const durationKey = requestedDuration.toLowerCase() as
    | typeof productTerms.lifetime
    | typeof productTerms.annual;
  let priceId = '';
  let subscriptionType = '';

  if (requestedSubscriptionType.toLowerCase() === ALERTS_SHORT_NAME) {
    // TODO: Add prices to the upgrade map
    // @ts-ignore
    priceId = AlertPrices[durationKey] || '';
    subscriptionType = SubscriptionType.SmartMoneyAlerts;
  } else if (requestedSubscriptionType.toLowerCase() === DASHBOARD_SHORT_NAME) {
    // @ts-ignore
    priceId = DashboardPrices[durationKey] || '';
    subscriptionType = SubscriptionType.DashboardStandard;
  }

  const duration = priceId ? durationKey : '';

  return { priceId, duration, subscriptionType };
};
