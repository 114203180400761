import * as React from 'react';
import { useRouter } from 'next/router';
import { getAffiliateId } from 'utils/shared/affiliate';
import { AFFILIATE_KEY } from 'utils/shared/affiliate/constants';
import api from 'services/client/api';

const AffiliateTracker: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [hasCheckedAffiliate, setHasCheckedAffiliate] = React.useState(false);
  const router = useRouter();

  React.useEffect(() => {
    if (router.query[AFFILIATE_KEY] && !hasCheckedAffiliate) {
      setHasCheckedAffiliate(true);

      const friend = router.query[AFFILIATE_KEY];
      const affiliateId = getAffiliateId(friend);
      const destinationPagePath = router.pathname;
      const referralUrl = document.referrer;

      if (affiliateId) {
        try {
          api.post('affiliate/referral', {
            payload: { destinationPagePath, affiliateId, referralUrl },
          });
        } catch (_error) {
          // Error should have been reported on the server...
        }
      }
    }
  }, [router.query[AFFILIATE_KEY], hasCheckedAffiliate]);

  return <>{children}</>;
};

export default AffiliateTracker;
